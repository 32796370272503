header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  margin: 0 100px 0 100px;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 20px;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  margin-right: 20px;
}

.logo img {
  max-height: 50px;
}

.title {
  font-size: 1.2rem;
  font-weight: bold;
}

ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  margin-left: 20px;
}

.vert-lane {
  height: 1.5rem;
  width: 1px;
  background-color: black;
  margin-left: 16px;
}

a {
  text-decoration: none;
  color: #333;
  transition: all 0.2s ease-in-out;
}

a:hover {
  color: #007bff;
}

.active-link {
  color: #007bff;
}
