table {
  border-collapse: collapse;
  width: 100%;
  max-width: 90vw;
  margin: 50px auto;
  border: 1px solid #ddd;
}

th,
td {
  padding: 12px;
  text-align: left;
  border: 1px solid #ddd;
}

th {
  font-weight: bold;
  background-color: #f2f2f2;
  border: 1px solid #ddd;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

td:first-child {
  font-weight: bold;
}

.status {
  text-align: center;
}

.member {
  display: inline-block;
  background-color: #3a96cc;
  color: white;
  border-radius: 5px;
  padding: 5px;
}

.not-member {
  display: inline-block;
  background-color: grey;
  color: white;
  border-radius: 5px;
  padding: 5px;
}

.join-button {
  background-color: transparent;
  color: grey;
  border: 2px solid grey;
  border-radius: 5px;
  padding: 5px;
}

.leave-button {
  background-color: transparent;
  color: red;
  border: 2px solid red;
  border-radius: 5px;
  padding: 5px;
}

.h2-missions {
  text-align: center;
  margin-bottom: 20px;
}
